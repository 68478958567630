<script lang="ts" setup>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ModalId } from "~/stores/entites";
const { t, currentLocale } = useLang();
const modalStore = useModal();
await useUser().getMyInfo();
const { getFullName, getImage } = storeToRefs(useUser());

const showLogoutModal = () => modalStore.toggle(ModalId.Logout);
</script>
<template>
  <Menu
    as="div"
    class="relative inline-block w-full min-w-max"
    :dir="currentLocale.dir"
  >
    <MenuButton
      class="w-full flex items-center text-sm hover:text-gray-400 focus:outline-none border rounded p-2"
    >
      <img
        v-if="getImage"
        :src="getImage"
        alt=""
        class="w-6 h-6 mx-1 text-primary-500 sensitive"
      />
      <Icon
        v-else
        name="heroicons:user-solid"
        class="flex w-6 h-6 rounded-full mx-1 text-lg text-primary-500 dark:text-gray-50"
      />
      <span
        class="flex-1 mx-2 font-semibold text-left sensitive truncate"
        :class="{
          'text-right': currentLocale.dir == 'rtl',
        }"
      >
        {{ getFullName ?? t("status.no_name") }}
      </span>
      <Icon
        name="heroicons:chevron-down-solid"
        class="flex mx-1 h-5 w-5"
        aria-hidden="true"
      />
    </MenuButton>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute left-0 text-left z-10 mt-2 w-56 origin-top-right rounded-md bg-white dark:bg-blue-900 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        :class="{
          'right-0 rtl:text-right': currentLocale.dir == 'rtl',
        }"
      >
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <Anchor
              to="/my-account/"
              :class="[
                active
                  ? 'bg-gray-100 dark:bg-gray-800 text-gray-900'
                  : 'text-gray-700 dark:text-gray-100',
                'block px-4 py-2 text-sm ',
              ]"
              >{{ t("components.logged_in_options.account_settings") }}</Anchor
            >
          </MenuItem>
          <MenuItem>
            <button
              class="block w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-800 hover:text-gray-900 dark:hover:text-gray-100 text-left rtl:text-right"
              @click="showLogoutModal"
            >
              {{ t("components.logged_in_options.logout") }}
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>
