<script setup lang="ts">
import { useTheme } from "../composables/useTheme";
import type { NuxtImg } from "#build/components";
const { currentTheme } = useTheme();

const props = defineProps({
  type: {
    type: String,
    default: "regular",
  },
  height: {
    type: String,
    default: "h-10",
  },
  width: {
    type: String,
    default: "w-40",
  },
});

const images = reactive<{
  [key: string]: string;
}>({
  regular: "logo",
  small: "mini",
  icon: "icon",
});

const img = computed(
  () =>
    "/assets/images/" +
    (currentTheme.value?.key === "dark"
      ? images[props.type] + "_dark.svg"
      : images[props.type] + "_light.svg"),
);
</script>
<template>
  <NuxtImg :src="img" alt="Tahadu logo" class="mr-2" :width="width" />
</template>
