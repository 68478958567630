<script lang="ts" setup>
// props
defineProps({
  outlined: {
    type: Boolean,
    default: false,
  },
});

// state
const { nextLocale, setLocale } = useLang();
</script>
<template>
  <div class="flex items-center">
    <button
      :class="{
        'flex items-center text-sm hover:text-gray-400 focus:outline-none border rounded-md p-2 w-full justify-between':
          outlined,
      }"
      class="py-2 px-2 flex items-center rtl:text-right text-left w-full cursor-pointer rounded-md dark:hover:bg-gray-700/30"
      @click="setLocale(nextLocale.code)"
    >
      <span
        :class="{
          'text-xl px-1': outlined,
          'text-sm mr-2 rtl:ml-2': !outlined,
        }"
      >
        {{ nextLocale.flag }}
      </span>
      <span v-if="!outlined" class="flex-1 truncate">
        {{ nextLocale.name }}
      </span>
    </button>
  </div>
</template>
