export interface IMenuItem {
  type: "link" | "button";
  text: string;
  href?: string;
  route?: unknown;
  loggedIn?: "only" | "not" | "both";
  order?: number;
}

export const useMenu = (): IMenuItem[] => {
  const { isLoggedIn } = useUser();
  const items = [
    {
      type: "link",
      text: "pages.coupons.nav",
      route: { path: "/coupons" },
      loggedIn: "both",
      order: 100,
    },
    {
      type: "link",
      text: "pages.favorite_wishlists.nav",
      route: { path: "/my-account/wishlists/favorites" },
      loggedIn: "only",
      order: 3,
    },
    {
      type: "link",
      text: "pages.my_claims.nav",
      route: { path: "/my-account/claims" },
      loggedIn: "only",
      order: 4,
    },
    {
      type: "link",
      text: "pages.my_wishlists.nav",
      route: { path: "/my-account/wishlists" },
      loggedIn: "only",
      order: 2,
    },
    {
      type: "button",
      text: "pages.auth.nav",
      route: { path: "/auth/login" },
      loggedIn: "not",
      order: 1,
    },
  ] satisfies IMenuItem[];

  return items
    .filter((item) =>
      isLoggedIn
        ? item.loggedIn === "only" || item.loggedIn === "both"
        : item.loggedIn === "not" || item.loggedIn === "both",
    )
    .sort((a, b) => a.order - b.order);
};
