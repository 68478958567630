<script lang="ts" setup>
const { app } = useAppConfig();
const { t, currentLocale } = useLang();
// get year
const year = new Date().getFullYear();
</script>

<template>
  <footer
    class="border-t lg:border-gray-900/10 dark:border-gray-50/[0.2]"
    :dir="currentLocale.dir"
  >
    <section
      class="max-w-8xl mx-auto px-4 lg:px-8 flex-1 flex w-full space-x-20"
    >
      <div class="w-full py-4 text-center ltr:md:text-left rtl:md:text-right">
        <div class="mb-1">
          {{ t(app.name) }}
        </div>
        <div class="text-xs text-gray-600 dark:text-gray-400">
          {{ t("components.footer.copyright", [year]) }}
        </div>
      </div>
    </section>
  </footer>
</template>
