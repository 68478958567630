<script lang="ts" setup>
const { t } = useLang();
// props
defineProps({
  outlined: {
    type: Boolean,
    default: false,
  },
  title: {
    type: Boolean,
    default: false,
  },
});

// state
</script>
<template>
  <div class="flex items-center">
    <NuxtLink
      :class="{
        'flex items-center text-sm hover:text-gray-400 focus:outline-none border rounded-md p-2 w-full justify-between':
          outlined,
        'hover:bg-gray-50 dark:hover:bg-gray-700/30': !outlined,
      }"
      class="p-2 flex items-center rtl:text-right text-left w-full cursor-pointer rounded-md"
      to="/faqs"
    >
      <span class="flex" :class="{ 'mr-2 rtl:ml-2': title }">
        <Icon
          name="heroicons:question-mark-circle"
          :class="{
            'h-5 w-5': !outlined,
            'h-7 w-7': outlined,
          }"
        />
      </span>
      <span v-if="title" class="flex-1">
        {{ t("pages.faqs.nav") }}
      </span>
    </NuxtLink>
  </div>
</template>
