<script lang="ts" setup>
import { type IMenuItem } from "../../composables/useMenu";
const { t, currentLocale } = useLang();
const isLoggedIn = computed(() => useUser().isLoggedIn);
const menu = computed((): IMenuItem[] => useMenu());

// show banner
const message = "banners.eid_fitr_mubarak"; // Eid Fitr Mubarak
const show = useCookie("show", {
  default: () => ({ show: true }),
  watch: true,
  maxAge: 259200, // 3 days
});
const dateToShow = computed(() => {
  // show banner from 9th to 15th of April (Eid Fitr)
  const now = new Date();
  const start = new Date(now.getFullYear(), 3, 9);
  const end = new Date(now.getFullYear(), 3, 15);
  return now >= start && now <= end;
});
</script>
<template>
  <BuilderNavbar>
    <template v-if="show.show && dateToShow" #banner>
      <div
        class="text-white text-sm text-center py-1 px-4 lg:px-8 bg-primary-500 capitalize flex items-center justify-center space-x-2"
      >
        <span class="cursor-pointer mx-2" @click="show.show = false">
          <Icon
            name="heroicons:x-mark-solid"
            class="h-6 w-6 inline"
            aria-hidden="true"
          />
        </span>
        <span class="font-bold flex text-center mx-2">
          {{ t(message) }}
        </span>
      </div>
    </template>
    <template #menu>
      <div class="hidden lg:flex mx-4 items-center gap-4 w-full">
        <nav
          class="text-md leading-6 font-semibold text-gray-600 dark:text-gray-300"
          role="navigation"
        >
          <ul class="flex items-center gap-1">
            <li v-for="(item, i) in menu" :key="i">
              <Anchor
                v-if="item.type === 'link'"
                :to="item.route ? item.route : undefined"
                :href="item.href ? item.href : undefined"
                class="w-full hover:no-underline hover:text-slate-900 hover:dark:text-white capitalize truncate p-4 rounded-md hover:bg-gray-50 dark:hover:bg-gray-700/30"
                >{{ t(item.text) }}
              </Anchor>
              <Button
                v-else-if="item.type === 'button'"
                :text="t(item.text)"
                size="sm"
                class="font-extrabold capitalize truncate !text-white p-4 rounded-md"
                :to="item.route ? item.route : undefined"
                :href="item.href ? item.href : undefined"
              />
            </li>
          </ul>
        </nav>
      </div>
      <div
        class="hidden lg:flex gap-2 border-gray-900/10 dark:border-gray-50/[0.2]"
      >
        <PageProfileOptions v-if="isLoggedIn" />
        <div
          class="flex gap-2 ltr:border-l rtl:border-r mx-4 px-4 border-gray-900/10 dark:border-gray-50/[0.2]"
        >
          <LanguageSwitcher />
          <ThemeSwitcher />
          <FAQLink />
        </div>
      </div>
    </template>
    <template #options="{ toggleOptions }">
      <ActionSheet @on-close="toggleOptions(false)">
        <ActionSheetBody>
          <ActionSheetHeader :text="t('components.other.menu')" />
          <nav
            class="leading-5 ltr:font-semibold text-gray-900 dark:text-gray-300"
          >
            <ul class="flex flex-col">
              <li
                v-for="(item, i) in menu"
                :key="i"
                class="flex justify-center w-full p-3 rounded-sm border-b border-gray-900/10 dark:border-gray-50/[0.2]"
                :class="{
                  'text-right': currentLocale.dir === 'rtl',
                }"
              >
                <Anchor
                  v-if="item.type === 'link'"
                  :to="item.route ? item.route : undefined"
                  :href="item.href ? item.href : undefined"
                  class="flex-1 hover:no-underline capitalize text-center"
                  >{{ t(item.text) }}
                </Anchor>
                <Button
                  v-else-if="item.type === 'button'"
                  :text="t(item.text)"
                  size="md"
                  type="primary"
                  class="flex-1 font-extrabold !text-white capitalize"
                  :to="item.route ? item.route : undefined"
                  :href="item.href ? item.href : undefined"
                ></Button>
              </li>
            </ul>
          </nav>
          <div
            class="mt-2 py-2"
            :class="{
              'border-b border-gray-900/10 dark:border-gray-50/[0.2] pb-4 mb-4':
                isLoggedIn,
            }"
            :dir="currentLocale.dir"
          >
            <PageProfileOptions v-if="isLoggedIn" />
          </div>
          <div
            class="gap-7 flex items-center justify-center"
            :dir="currentLocale.dir"
          >
            <ThemeSwitcher type="select-box" />
            <LanguageSwitcher :outlined="true" />
            <FAQLink :title="false" :outlined="true" />
          </div>
        </ActionSheetBody>
        <Button
          :text="t('actions.close')"
          type="secondary ring-offset-2 ring-offset-gray-900/10 dark:ring-offset-gray-50/[0.2]"
          @click.prevent="toggleOptions(false)"
        />
      </ActionSheet>
    </template>
    <!-- <template #drawer>
      <slot name="drawer" />
    </template> -->
  </BuilderNavbar>
</template>
