<script lang="ts" setup>
import { Menu, MenuButton } from "@headlessui/vue";
import { type IThemeSettingsOptions } from "../composables/useTheme";

// micro compiler
const props = defineProps({
  type: {
    type: String,
    default: "dropdown-right-top",
  },
  title: {
    type: Boolean,
    default: false,
  },
});
const { t } = useLang();
const { currentTheme, availableThemes } = useTheme();
// state
const themeSettings = useState<IThemeSettingsOptions>("theme.settings");
const currentStyle = toRef(props, "type");
// methods
function toggle() {
  if (themeSettings.value === "light") {
    themeSettings.value = "dark";
  } else if (themeSettings.value === "dark") {
    themeSettings.value = "light";
  }
}
</script>

<template>
  <div class="flex items-center">
    <button
      v-if="currentStyle === 'dropdown-right-top'"
      class="not-sr-only p-2 rounded-md hover:bg-gray-50 dark:hover:bg-gray-700/30"
      @click="toggle()"
    >
      <span class="flex justify-center items-center dark:hidden">
        <Icon name="heroicons:sun" class="text-gray-900 h-5 w-5" />
      </span>
      <span class="justify-center items-center hidden dark:flex">
        <Icon name="heroicons:moon-solid" class="text-gray-50 h-5 w-5" />
      </span>
    </button>
    <Menu
      v-if="currentStyle === 'select-box'"
      as="div"
      class="relative inline-block text-left rtl:text-right w-full"
    >
      <div>
        <MenuButton
          class="flex items-center text-sm hover:text-gray-400 focus:outline-none border rounded-md p-2 w-full justify-between"
          @click="toggle()"
        >
          <div class="flex items-center justify-center">
            <span class="justify-center items-center">
              <Icon
                v-if="currentTheme.key === 'light'"
                name="heroicons:sun"
                class="text-gray-900"
                :class="{
                  'h-5 w-5': type !== 'select-box',
                  'h-7 w-7': type === 'select-box',
                }"
              />
              <Icon
                v-else-if="currentTheme.key === 'dark'"
                name="heroicons:moon-solid"
                class="text-gray-50 h-5 w-5"
                :class="{
                  'h-5 w-5': type !== 'select-box',
                  'h-7 w-7': type === 'select-box',
                }"
              />
            </span>
            <span v-if="title" class="mx-2 text-sm font-semibold">
              {{
                t(
                  availableThemes.filter((k) => k.key === themeSettings)[0]
                    .text,
                )
              }}
            </span>
          </div>
        </MenuButton>
      </div>
    </Menu>
  </div>
</template>
